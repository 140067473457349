import { AnalyticsConfig, AppConfig } from '@neptune/config-core-domain';

import { isOnPremiseDeployment } from 'common/deploymentModes';
import { isAppRunningInE2EContext } from 'common/e2e';

import config from 'config';

import * as ga from './google-analytics';
import * as gtm from './gtm-inject';
import * as heap from './heap-analytics';
import * as intercom from './intercom';
import * as userflow from './userflow-analytics';

initAnalytics(config, isOnPremiseDeployment(), isAppRunningInE2EContext());

function initAnalytics(
  config: AppConfig & AnalyticsConfig,
  isEnterprise: boolean,
  isE2EContext: boolean,
) {
  const shouldTurnOffAnalytics = isEnterprise || isE2EContext;

  if (config.allowUserflow || !shouldTurnOffAnalytics) {
    userflow.init(config.userflowId);
  }

  if (config.allowHeap || !shouldTurnOffAnalytics) {
    heap.init(config.heapId);
  }

  if (shouldTurnOffAnalytics) {
    return;
  }

  if (config.appEnv === 'dev') {
    // eslint-disable-next-line
    console.warn(
      'It looks like you are in local development mode so that analytics script will not be attached',
    );
    return;
  }

  gtm.init(config.isProduction);
  intercom.init(config.intercomId);
  ga.init(config.isProduction);
}
